<template>
  <Layout>
    <PageHeader :title="title" :items="items"/>

    <div class="row pb-5">
      <div class="col-2">
        <div class="form-group">
          <label for="tabela_ano">
            Tabela Cbhpm
          </label>
          <select class="form-control"
                  @change="ProcurarCodigoTabelaCbhpm"
                  id="tabela_ano"
                  name="tabela_ano" v-model="selectedAno">
            <option v-for="(ano, index) in data_list" :key="index" :value="ano.Ano"
                    :selected="ano.Ano === data_list[data_list.length -1].Ano">
              {{ ano.Ano }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-8">
        <div class="form-group">
          <label>
            Procurar
          </label>
          <input type="text" v-model="search" class="form-control">
        </div>
      </div>
      <div class="col-2">
        <div class="form-group">
          <label>
            Server
          </label>
          <div class="form-check form-check-dark">
            <input class="form-check-input" type="checkbox" id="formCheck12" checked>
            <label class="form-check-label" for="formCheck12">
            </label>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="table-responsive table-card">
          <table class="table align-middle table-nowrap"
                 id="customerTable">
            <thead class="table-light text-muted">
            <tr>

              <th>Codigo</th>
              <th>Ano</th>
              <th>Valor</th>
              <th>Descrição</th>
              <th>Porte Anestesico</th>
              <th>Porte Cirurgico</th>

            </tr>
            </thead>
            <tbody class="list form-check-all">
            <tr v-for="(data_row, index) of data.data" :key="index">

              <TrCodigoCalculadora :data="data_row" />

            </tr>
            </tbody>
          </table>
        </div>
        <div class="d-flex justify-content-end mt-3">
          <div class="col-sm-12 text-end">
            Página {{ page }} de {{ totalPages }}
          </div>
        </div>
        <div class="d-flex justify-content-end ">

          <div class="row mt-3">
            <div class="col-sm-12">
            <button @click="getItensAnterior" class="btn btn-warning btn-sm d-inline-block me-2">Anterior</button>
            <button @click="getItensProxima" class="btn btn-warning btn-sm d-inline-block me-2">Proxima</button>
            </div>
          </div>



        </div>

      </div>
      <!-- end card body -->

    </div>

  </Layout>
</template>
<style>

</style>
<script>
import Layout from "../../layouts/main.vue";
import PageHeader from "../../components/page-header";
import appConfig from "../../../app.config";
import axios from "axios";
import TrCodigoCalculadora from "../../components/TrCodigoCalculadora.vue";

export default {
  page: {
    title: "Calculadora",
    meta: [
      {
        name: "description",
        content: appConfig.description
      }
    ],
  },
  data() {
    return {
      title: "Calculadora",
      items: [
        {
          text: "EASY UX",
          href: "/",
        },
        {
          text: "Calculadora",
          active: true,
        },
      ],
      data:{},
      page: 1,
      search: '',
      totalPages: 1,
    };
  },
  components: {
    TrCodigoCalculadora,
    Layout,
    PageHeader,
  },
  methods: {
    async TabelaCbhpm() {

      try {

        const result = await axios.get(process.env.VUE_APP_API + '/v1/calculadora');
        this.data_list = result.data;
        this.selectedAno = result.data[result.data.length - 1].Ano;
        await this.ProcurarCodigoTabelaCbhpm();

      } catch (e) {
        console.log(e);
      }
    },
    async ProcurarCodigoTabelaCbhpm() {

      try {

        let ano_selecionado = this.selectedAno;

        this.data = [];

        const result = await axios.get(process.env.VUE_APP_API + '/v1/calculadora/tabela',{
          params: {
            page : this.page,
            anoTabela : ano_selecionado,
            search :  this.search,
          }
        });

        this.data = result.data;
        this.totalPages = result.data.last_page;


      } catch (e) {
        console.log(e);
      }
    },
    getItensAnterior() {
      if (this.page > 1) {
        this.page--;
        this.ProcurarCodigoTabelaCbhpm();
      }
    },
    getItensProxima() {

      if (this.page < this.totalPages) {
        this.page++;
        this.ProcurarCodigoTabelaCbhpm();
      }

    },
  },
  mounted() {
    this.TabelaCbhpm();

  },
  watch: {
    search() {
      this.ProcurarCodigoTabelaCbhpm()
    }
  },
  filters: {

  },
  computed: {
    lastPage() {
      return Math.ceil(this.items.length / 10)
    }
  },
  created() {

  }
}
</script>