<template>
  <td>{{ data_template.Codigo }}</td>
  <td>{{ data_template.Ano }}</td>
  <td class="text-success">R$ {{ data_template.Valor }}</td>
  <td style="word-break: break-all; widows: 60%" v-html="data_template.Descricao"></td>
  <td>{{ data_template.PorteAnestesico }}</td>
  <td>{{ data_template.PorteCirurgico }}</td>
</template>

<script>
export default {
  name: "TrCodigoCalculadora",
  props: [
    'data'
  ],
  data() {
    return {
      data_template: {
        Codigo: '',
        Valor: '',
        Descricao: '',
        PorteAnestesico: '',
        PorteCirurgico: '',
        Ordem: '',
        Id: '',
        SubgrupoId: '',
        GrupoId: '',
        Ano: '',
      }
    }
  },
  mounted() {

    this.data_template = this.data;

    if(this.data_template.GrupoId === '' && this.data_template.SubgrupoId === ''){
      this.data_template.Descricao = '<span class="badge bg-soft-danger text-danger align-middle">GRUPO</span> ' +  this.data_template.Descricao;
    }

    if(this.data_template.GrupoId !== '' && this.data_template.SubgrupoId === ''){
      this.data_template.Descricao = '<span class="badge bg-soft-success text-success align-middle">SUBGRUPO</span> ' +  this.data_template.Descricao;
    }

  },
}
</script>

<style scoped>

</style>